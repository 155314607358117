<template>
    <div>
            <v-tabs
                fixed-tabs
                background-color="blue-grey darken-2"
                dark
                style="margin-top: -32px;margin-left: -32px;"
                v-model="tab"
            >
            <v-tab 
                v-for="item in items"
                :key="item.type"
                @click="filterDimension(item.type)"
            >
                {{ item.name }}
            </v-tab>          
        </v-tabs>
         <v-row>
            <v-col class="mt-8">
                <v-dialog
                    v-model="dialogDimension"
                    persistent
                    width="700"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="mb-5"
                            @click="newDimension"
                        >
                            Nueva dimensión
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="px-4 py-2" v-if="edit_mode">
                                Editar dimensión: <small>{{ infoDimension.name}}</small>
                            </span>
                            <span class="px-4 py-2" v-else>Nueva dimensión</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-radio-group 
                                        class="px-4 py-2 mb-0 pb-0" 
                                        v-model="infoDimension.subcategory"
                                    >
                                        <v-row>
                                            <v-col>
                                                <v-radio
                                                    class="elevation-2 rounded-lg py-4 px-4"
                                                    label="Dimensión Regular"
                                                    value="0"
                                                    checked
                                                ></v-radio>
                                            </v-col>
                                            <v-col>
                                                <v-radio
                                                    class="elevation-2 rounded-lg py-4 px-4"
                                                    label="Dimensión de Política Pública"
                                                    value="2"
                                                ></v-radio>
                                            </v-col>
                                            <v-col>
                                                <v-radio
                                                    class="elevation-2 rounded-lg py-4 px-4"
                                                    label="Dimensión Secretaría"
                                                    value="1"
                                                ></v-radio>
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                </v-row>
                                <v-row>
                                    <v-col cols="12 pt-0 pb-0">
                                        <v-text-field
                                            label="Nombre dimensión"
                                            v-model="infoDimension.name"
                                            :error-messages="nameDimensionErrors"
                                            required
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12 pt-0 pb-0">
                                        <v-text-field
                                            v-model="infoDimension.description"
                                            :error-messages="descriptionDimensionErrors"
                                            label="Descripción dimensión"
                                            required>   
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <img 
                                            :src="infoDimension.img" 
                                            v-if="infoDimension.img"
                                            style="width: 300px; height: 200px; object-fit: cover"
                                            class="rounded-lg"
                                        >
                                        <img 
                                            src="" 
                                            v-else
                                            style="width: 300px; height: 200px; object-fit: cover"
                                            class="rounded-lg"
                                        >
                                        <v-file-input
                                            accept="image/*"
                                            v-model="infoDimension.img"
                                            @change="onDimensionImage"
                                            :error-messages="imgDimensionErrors"
                                            prepend-icon="mdi-camera"
                                            label="Imagen fondo dimensión">
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-color-picker
                                            v-model="infoDimension.color"
                                            mode="hexa"
                                        ></v-color-picker>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="dialogDimension = false">
                                    Cerrar
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="saveDimension">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="item in items"
                :key="item.type"
                
            >
                <v-card
                    flat
                >
                    <v-row class="pb-5" style="background-color: #f5f5f5 !important">
                        <v-col lg="3" xl="3" md="4" sm="4" v-for="item in dimensions_filter" v-bind:key="item.id">
                            <v-card>
                                <v-container class="dimension_container" :style="{'background-image': `url(${item.img_dimension.url})`, 'background-size': 'cover', 'background-position': 'center'}">
                                    <div class="dimension_container__title" :style="{'background-color':item.color_dimension}">
                                        <p>
                                            {{item.name_dimension}}
                                        </p>
                                    </div>
                                </v-container>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on" @click="setDimenson(item)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar dimensión</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on" @click="getSegments(item.id)">
                                                <v-icon>mdi-arrange-send-to-back</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Segmentos</span>
                                    </v-tooltip>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        
       
        
    <v-dialog
        v-model="dialogSegment"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable>
        <v-overlay :value="loadSegments">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-card tile>
            <v-toolbar
                flat
                dark
                color="primary">
                <v-btn
                    icon
                    dark
                    @click="dialogSegment = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Segmentos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                        dark
                        text
                        @click="newSegment">
                        <v-icon left>
                            mdi-card-plus
                        </v-icon>
                        Nuevo segmento
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <!-- INFORMACIÓN DE CARDS -->
                <v-row>
                    <v-col cols="6" v-for="(value, key) in Segments" :key="key">
                        <v-card class="mt-3">
                            <v-toolbar
                                flat
                                color="primary"
                                dark>
                                <v-toolbar-title>{{value.name}}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>

                                    <v-tooltip bottom v-if="Segments[key].editable">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="saveSegment(key)"
                                            >
                                                <v-icon>mdi-content-save</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Guardar información</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="Segments[key].editable = true"
                                            >
                                                <v-icon>mdi-content-save-edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar información</span>
                                    </v-tooltip>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-tabs>
                                <v-tab>
                                    <v-icon left>
                                    mdi-card-text
                                    </v-icon>
                                    Información
                                </v-tab>
                                <v-tab>
                                    <v-icon left>
                                    mdi-ballot
                                    </v-icon>
                                    Lista
                                </v-tab>
                                    <v-tab-item>
                                        <v-card flat>
                                            <v-card-text>
                                                
                                                <v-form>
                                                    <v-container>
                                                    <v-row>
                                                        <v-col
                                                            cols="6">
                                                            <v-text-field
                                                                label="Nombre segmento"
                                                                v-model="Segments[key].name"
                                                                :disabled="!Segments[key].editable"
                                                                required></v-text-field>
                                                        </v-col>
                                                        <v-col
                                                            cols="6">
                                                            <v-file-input
                                                                accept="image/*"
                                                                label="Ícono"
                                                                v-model="Segments[key].icon"
                                                                :disabled="!Segments[key].editable"
                                                                prepend-icon="mdi-camera"
                                                            ></v-file-input>
                                                        </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-form>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-card flat>
                                            <v-card-text>
                                                <v-form>
                                                    <v-container>
                                                    <v-row>
                                                        <v-col>
                                                            <v-text-field
                                                                clear-icon="mdi-close-circle"
                                                                clearable
                                                                label="Nombre ítem"
                                                                v-model="Segments[key].list.name"
                                                                :disabled="!Segments[key].editable"
                                                                type="text"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-btn
                                                                class="mx-2"
                                                                fab
                                                                dark
                                                                color="indigo"
                                                                :disabled="!Segments[key].editable"
                                                                @click="addToList(key)">
                                                                <v-icon dark>
                                                                    mdi-plus
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    </v-container>
                                                </v-form>
                                                <v-chip-group
                                                    active-class="primary--text"
                                                    column
                                                >
                                                    <v-chip @click:close="deleteItem(keyItem, key)" close close-icon="mdi-delete" v-for="(item, keyItem) in value.list.items" :key="keyItem">
                                                        {{item.name}}
                                                    </v-chip>
                                                </v-chip-group>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                            </v-tabs>
                        </v-card>
                    </v-col>
                </v-row>
                
                <!-- INFORMACIÓN DE CARDS -->
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>
import { required, email, numeric } from 'vuelidate/lib/validators'
export default {
    data() {
        return {
            switchBtn:false,
            routeImg:'',
            loadForm: false,
            dialogDimension: false,
            dialogSegment: false,
            dimensions: [],
            dimensions_filter: [],
            dimensionId: null,
            infoDimension: {
                name: '',
                description: '',
                img: '',
                color: null,
                subcategory: '0',
            },
            Segments: [],
            itemList: {
                id: null,
                name: null
            },
            loadSegments: false,
            tab: null,
            items: [
                {name: 'Todas las Dimensiones', type: 'All'},
                {name: 'Regulares', type: 'Regular'},
                {name: 'Dimensiones de Políticas Públicas', type: 'Public'},
                {name: 'Secretarías', type: 'Subcategory'},
            ],
            edit_mode: false
        }
    },
    validations: {
        infoDimension: {
            name: {required},
            description: {required},
            img: {required}
        }
    },
    computed: {
        nameDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.name.$dirty) return errors
            !this.$v.infoDimension.name.required && errors.push('Este campo es requerido.')
            return errors
        },
        descriptionDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.description.$dirty) return errors
            !this.$v.infoDimension.description.required && errors.push('Este campo es requerido.')
            return errors
        },
        imgDimensionErrors(){
            const errors = []
            if (!this.$v.infoDimension.img.$dirty) return errors
            !this.$v.infoDimension.img.required && errors.push('Este campo es requerido.')
            return errors
        },
        url(){
            if (!this.infoDimension.img) return;
            return this.routeImg;
        },

    },
    methods: {
        setDimenson(data){
            this.switchBtn=false
            console.log('la dimension',data )
            this.infoDimension= {
                id:data.id,
                name: data.name_dimension,
                description: data.text_dimension,
                img: data.img_dimension.url,
                color: data.color_dimension,
                subcategory: data.subcategory == null ? '0' : data.subcategory.toString()
            },
            this.edit_mode = true;
            this.dialogDimension = true;
            //return url = data.img_dimension.url

        },
        onDimensionImage(e){
            var files = e
            this.routeImg =URL.createObjectURL(files)
            return this.createPrincipalImage(files)
        },
        createPrincipalImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.infoDimension.img = reader.result
        }
      },
        newDimension(){
            this.edit_mode = false;
            console.log("el boton",this.switchBtn)
            this.switchBtn=true
            console.log("el boton",this.switchBtn)
            this.infoDimension = {
                name: '',
                description: '',
                img: null,
                color: null,
                subcategory: '0'
            };
            this.dialogDimension = true;
        },
        newSegment(){
            this.Segments.push({
                id: null,
                name: null,
                icon: null,
                list: {
                    name: null,
                    items: []
                },
                editable: true
            });
        },
        saveSegment(keySegment){

            this.$http.post('architectures', 
                {
                    data: {
                        Dimension: this.dimensionId,
                        Segment: this.Segments[keySegment]
                    }
                //})
                }, {headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    console.log('OK', response.body)
                    this.getSegments(this.dimensionId);
                }).catch(error => {
                    console.log('Error', error)
                });
        },
        addToList(keySegment){
            this.Segments[keySegment].list.items.push({
                id: null,
                name: this.Segments[keySegment].list.name
            });
            
            this.Segments[keySegment].list.name = null;
            
        },
        deleteItem(keyItem, keySegment){
            this.Segments[keySegment].list.items.splice(keyItem, 1);
        },
        getSegments(Dimension){
            this.loadSegments = true;
            // Consultar segmentos por el id de la dimensión

            //this.$http.get('architectures/dimension/'+Dimension, {headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            this.$http.get('architectures/dimension/'+Dimension)
            .then(response => {
                console.log('Segmentos consultados', response);
                this.Segments = response.body.Segments;
                this.loadSegments = false;
            }).catch(error => {
                console.log('Error al consultar los segmentos', error)
            })

            this.dialogSegment = true;
            this.dimensionId = Dimension;
            console.log('Id dimension', Dimension)
        },
        getDimension(){
            this.$http.get('dimensions/townhall/'+this.getClientSelect().id)
            //this.$http.get('dimensions/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            .then(response => {
                this.dimensions = response.body;
                this.dimensions_filter = response.body;
                this.tab = null;
            }).catch(error => {
                console.log('Error al consultar las dimensiones', error)
            })
        },
        async saveDimension(){
            console.log("aqui estoy :::: ",this.infoDimension)
            console.log("el boton esta en ",this.switchBtn)
            if (this.switchBtn == true){
                try{
                    const dimension = await this.$http.post('dimensions', { data: {
                        attributes: {
                            townhall_id:this.getClientSelect().id,
                            name_dimension:this.infoDimension.name,
                            img_dimension:this.infoDimension.img,
                            color_dimension:this.infoDimension.color.hex,
                            text_dimension:this.infoDimension.description,
                            subcategory: this.infoDimension.subcategory.toString(),
                    }}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                    this.dialogDimension=false
                }catch(error){
                    console.error(error)
                }
            }else{
                try{
                    const editDimension = await this.$http.put('dimensions/'+this.infoDimension.id, 
                    {
                        data: {
                            attributes: {
                                name_dimension: this.infoDimension.name,
                                img_dimension: this.infoDimension.img,
                                color_dimension: this.infoDimension.color,
                                text_dimension: this.infoDimension.description,
                                subcategory: this.infoDimension.subcategory.toString(),
                            }
                        }
                    }
                ,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                    this.dialogDimension=false
                }catch(error){
                    console.error(error)
                }
            }
            this.getDimension()
        },
        filterDimension(type){
            if(type == 'All'){
                this.dimensions_filter = this.dimensions;
            }
            if(type == 'Regular'){
                this.dimensions_filter = this.dimensions.filter(item => item.subcategory == '0' || (item.subcategory != '1' && item.subcategory != '2'));
            }
            if(type == 'Public'){
                this.dimensions_filter = this.dimensions.filter(item => item.subcategory == '2');
            }
            if(type == 'Subcategory'){
                this.dimensions_filter = this.dimensions.filter(item => item.subcategory == '1');
            }
        }
    },
    created() {
        this.getDimension();
    },
    
}
</script>